@charset "utf-8";

///////////////////////////////////////////////////////////
// dotted
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-dotted {
  background-position: top left 0px;
  background-repeat: repeat-x;
  background-size: 1.0em .4em;
  background-image: radial-gradient(.1em .1em at center center,$clr_orange,$clr_orange 100%,transparent,transparent);
  padding-top: .2em;
}