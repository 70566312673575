@charset "utf-8";

///////////////////////////////////////////////////////////
// container
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.l-container {
  width: min(92%, 111.4rem);
  margin: 0 auto;
}

.l-container-w92 {
  width: min(92%, 92rem);
  margin: 0 auto;
}

.l-container-w72 {
  width: min(92%, 72rem);
  margin: 0 auto;
}

.l-container-w52 {
  width: min(92%, 52rem);
  margin: 0 auto;
}

