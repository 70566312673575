@charset "utf-8";

///////////////////////////////////////////////////////////
// mountain
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-mountain {
  position: relative;
  padding: 5.85vw 0;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 5.85vw;
  }

  &::before {
    top: 1px;
    background: url("img/common/img_bg02.svg") no-repeat;
    background-size: cover;
  }

  &::after {
    bottom: 1px;
    background: url("img/common/img_bg03.svg") no-repeat;
    background-size: cover;
  }
}