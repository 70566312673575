@charset "utf-8";

///////////////////////////////////////////////////////////
// contents
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.l-contents {
  padding: clamp_size(50, 100) 0 clamp_size(70, 100);

  &--t70 {
    padding: clamp_size(50, 70) 0 clamp_size(70, 100);
  }

  &--noBottom {
    padding-bottom: 0;
  }
}