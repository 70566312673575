@charset "utf-8";

///////////////////////////////////////////////////////////
// index
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

// ---------------------------------------------------------
// fv
// ---------------------------------------------------------
.fv__inner {
  padding-top: 6.3rem;
  background-color: $clr_light_green;
  overflow: hidden;
  @include mq-down(md) {
    padding-top: clamp_size(75,100,375,768);
  }
}

.fv__wrap {
  position: relative;
  max-width: 128rem;
  min-height: clamp_size(402, 760);
  margin: 0 auto;
}

/* FV メッセージ
---------------------------------------------------------- */
.fv__content {
  position: relative;
  padding-top: clamp_size(70,120,576,1280);
  padding-bottom: clamp_size(34,84,576,1280);
  z-index: 5;
  @include mq-down(sm) {
    padding-top: clamp_size(30,40,375,576);
    padding-bottom: clamp_size(38,48,375,576);
  }
}

.fv__catch {
  display: inline-block;
  width: clamp_size(220, 260);
  padding: .2em 0;
  font-size: clamp_size(16, 20);
  font-weight: 500;
  color: #fff;
  text-align: center;
  background-color: $clr_green;
  border-radius: 24px;
}

.fv__mainMessage {
  font-size: clamp_size(32, 42);
  font-weight: 900;
  line-height: 1.5;
  color: $clr_green;
  margin-top: .6em;
  -webkit-text-stroke: 1px $clr_light_green;
  text-stroke: 1px $clr_light_green;
}

.fv__mainMessage_dot {
  background-position: top left 0px;
  background-repeat: repeat-x;
  background-size: 1.0em .3em;
  background-image: radial-gradient(.07em .07em at center center,$clr_orange,$clr_orange 100%,transparent,transparent);
  padding-top: .15em;
}

.fv__subMessage {
  font-size: clamp_size(14, 18);
  font-weight: 500;
  margin-top: .5em;
  text-shadow: 1px 1px 0 $clr_light_green, -1px -1px 0 $clr_light_green,
  -1px 1px 0 $clr_light_green,  1px -1px 0 $clr_light_green,
  1px 0 0 $clr_light_green, -1px  0 0 $clr_light_green,
  0 1px 0 $clr_light_green,  0 -1px 0 $clr_light_green;
}

/* FV アピールポイント
---------------------------------------------------------- */
.fv__appealList {
  position: relative;
  display: flex;
  // max-width: 42.7rem;
  margin-top: 2rem;
  z-index: 5;
  @include mq-down(sm) {
    margin-top: 1.5rem;
  }
  @media screen and (max-width: 375px) {
    justify-content: space-between;
  }
}

.fv__appealItem {
  position: relative;
  display: grid;
  place-content: center;

  & + & {
    margin-left: clamp_size(10, 15);
    @media screen and (max-width: 359px) {
      margin-left: 5px;
    }
  }

  &:nth-child(1) {
    .fv__appealItem_bg {
      img {
        aspect-ratio: 135 / 130;
      }
    }
  }

  &:nth-child(2) {
    .fv__appealItem_bg {
      img {
        aspect-ratio: 135 / 130;
      }
    }
  }

  &:nth-child(3) {
    .fv__appealItem_bg {
      img {
        aspect-ratio: 127 / 132;
      }
    }
  }
}

.fv__appealItem_bg {
  width: 100%;
  height: 100%;
  
  img {
    width: 100%;
    object-fit: cover;
  }
}

.fv__appealItem_text {
  position: absolute;
  top: 50%;
  left:50%;
  transform: translate(-50%, -50%);
  width: 100%;
  font-size: clamp_size(16, 20);
  font-weight: 700;
  line-height: 1.3;
  text-align: center;
  color: #fff;
  z-index: 1;

  .small {
    font-size: clamp_size(12, 13);
    font-weight: 500;
    line-height: 1;
  }
}

/* FV 重要なお知らせ
---------------------------------------------------------- */
.fv__news {
  position: relative;
  margin-top: 2rem;
  max-width: 53.2rem;
  padding: 2rem;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  z-index: 5;
}

.fv__news_title {
  font-size: 1.4rem;
  font-weight: 700;
  color: $clr_red;
  text-align: center;
}

.fv__newsList {
  margin-top: 1rem;
}

.fv__newsItem {
  & + & {
    margin-top: .8rem;
  }
}

.fv__newsItem_link {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 1.8rem;
  @include mq-down(sm) {
    flex-direction: column;
    align-items: flex-start;
  }

  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    content: "\f0da";
    font-size: 1em;
    font-weight: 900;
    font-family: "Font Awesome 6 Free";
    color: $clr_red;
    @include mq-down(sm) {
      transform: translateY(-16%);
    }
  }

  @include hover {
    .fv__newsItem_title {
      color: $clr_red;
    }
  }
}

.fv__newsItem_time {
  line-height: 1;

  time {
    font-size: clamp_size(12, 14);
    color: $clr_gray;
  }
}

.fv__newsItem_title {
  font-size: clamp_size(12, 14);
  font-weight: 500;
  margin-left: 1.5rem;
  transition: color .3s ease-out;
  @include mq-down(sm) {
    margin-left: 0;
    margin-top: .2em;
  }
}

/* FV予約バナー
---------------------------------------------------------- */
.fv__reservation {
  position: absolute;
  right: 0;
  bottom: clamp(3.4rem,-.6909090909rem + 7.1022727273vw,8.4rem);
  transition: 0.3s all linear;
  &:hover {
    opacity: 0.7;
  }
  @include mq-down(lg) {
    display: block;
    margin-top: 32px;
    position: static;
    text-align: center;
    & figure {
      & img {
        width: 100%;
        height: auto;
      }
    }
  }
}


/* FV背景
---------------------------------------------------------- */
.fv__bg {
  position: absolute;
  top: 0;
  right: max(-12.4vw, -18rem);
}

.fv__bg_wrap {
  position: relative;
  width: clamp_size(384, 1124);
  height: clamp_size(270, 745);

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: calc(100% - 1px);
    height: calc(100% - 2px);
    border-top: 1px solid $clr_light_green;
    border-left: 1px solid $clr_light_green;
    border-bottom: 1px solid $clr_light_green;
    background-color: transparent;
    z-index: 1;
  }

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("img/top/fv_morphing.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// ---------------------------------------------------------
// このようなお悩みありませんか？
// ---------------------------------------------------------
.problem__list {
  display: flex;
  justify-content: center;
  padding: 6rem 0 4rem;
  margin-top: clamp_size(20, 40);
  background-color: $clr_light_green;
  border-radius: 20px;
  @include mq-down(lg) {
    display: grid;
    gap: 5.4rem;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
  }
  @include mq-down(md) {
    padding: 3rem 0;
  }
  @include mq-down(sm) {
    gap: clamp_size(34,54,375,576) clamp_size(24,34,375,576);
  }
  @media screen and (max-width: 359px) {
    gap: 2.4rem 1.6rem;
  }
}

.problem__item {
  & + & {
    margin-left: clamp_size(24,64,992,1280);
    @include mq-down(lg) {
      margin-left: 0;
    }
  }

  &:nth-child(1),
  &:nth-child(3) {
    margin-left: auto;

    .problem__item_thumb {
      img {
        @include mq-down(sm) {
          width: clamp_size(70,90,375,576);
        }
      }
    }
  }

  &:nth-child(2),
  &:nth-child(4) {
    margin-right: auto;

    img {
      @include mq-down(sm) {
        width: clamp_size(80,110,375,576);
      }
    }
  }
}

.problem__item_thumb {
  display: grid;
  place-content: center;
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  background-color: #fff;
  @include mq-down(sm) {
    width: clamp_size(140,200,375,576);
    height: clamp_size(140,200,375,576);
  }
  @media screen and (max-width: 359px) {
    width: 12rem;
    height: 12rem;
  }
}

.problem__item_text {
  font-size: clamp_size(14, 18);
  font-weight: 500;
  text-align: center;
  margin-top: 1.2em;
}

.problem__announce {
  font-size: clamp_size(14, 18);
  font-weight: 500;
  text-align: center;
  margin-top: 2em;
}

.problem__reservation {
  text-align: center;
  display: block;
  margin-top: clamp_size(28, 50);
  transition: 0.3s all linear;
  &:hover {
    opacity: 0.7;
  }
}

// ---------------------------------------------------------
// 診療案内
// ---------------------------------------------------------
.information__inner {
  display: flex;
  justify-content: space-between;
  margin-top: clamp_size(20, 30);
  @include mq-down(md) {
    flex-direction: column-reverse;
  }
}

.information__content,
.information__thumb {
  width: 45.8%;
  @include mq-down(md) {
    width: 100%;
  }
}

.information__content {
  @include mq-down(md) {
    margin-top: 3rem;
  }
}

.information__content_link {
  margin-top: clamp_size(44, 64);
}

.information__thumb {
  text-align: center;
}

// ---------------------------------------------------------
// 当院の特長
// ---------------------------------------------------------
.feature {
  background-color: $clr_light_orange;
}

.feature__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feature__head_link {
  @include mq-down(xl) {
    display: none;
  }
}

.feature__inner {
  padding: 3rem 0 4rem;
  @include mq-down(xl) {
    max-width: 74rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.feature__list {
  display: flex;
  justify-content: space-between;
  @include mq-down(xl) {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -5rem;
    margin-left: -4.2rem;
  }
  @include mq-down(md) {
    margin-top: -2rem;
    margin-left: -2rem;
  }
}

.feature__item {
  position: relative;
  width: 20.4rem;
  padding-top: 2rem;
  @include mq-down(xl) {
    margin-top: 5rem;
    margin-left: 4.2rem;
  }
  @include mq-down(md) {
    width: clamp_size(144,184,375,768);
    margin-top: 2rem;
    margin-left: 2rem;
  }

  &:last-child {
    display: none;
    @include mq-down(xl) {
      display: grid;
      place-content: center;

      .c-circleLink {
        transform: translateY(-1rem);
      }
    }
    @media screen and (max-width: 334px) {
      margin-top: 5rem;
    }
  }
}

.feature__item_num {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 5.7rem;
  font-weight: 700;
  line-height: 1;
  color: $clr_orange;
  @include mq-down(md) {
    font-size: clamp_size(47,57,375,768);
  }
}

.feature__item_thumb {
  display: grid;
  place-content: center;
  width: 20.4rem;
  height: 20.4rem;
  border-radius: 50%;
  background-color: #fff;
  @include mq-down(md) {
    width: clamp_size(144,184,375,768);
    height: clamp_size(144,184,375,768);
  }

  img {
    @include mq-down(md) {
      width: 90%;
      margin: 0 auto;
    }
    @include mq-down(sm) {
      width: 80%;
    }
  }
}

.feature__item_text {
  font-size: clamp_size(14, 18);
  font-weight: 500;
  text-align: center;
  line-height: 1.3;
  margin-top: 1.2em;
}

// ---------------------------------------------------------
// 佐世保駅前メンタルクリニックについて
// ---------------------------------------------------------
.about__list {
  display: grid;
  gap: clamp_size(20, 50, 768, 1280);
  grid-template-columns: repeat(3, 1fr);
  margin-top: clamp_size(30, 40);
  @include mq-down(md) {
    grid-template-columns: repeat(1, 1fr);
    gap: 4rem;
  }
}

.about__item {
  text-align: center;
  padding: clamp_size(30, 50) clamp_size(10, 20, 768, 1280) clamp_size(34, 44);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  @include mq-down(md) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.about__item_menu {
  font-weight: 500;
  margin-top: clamp_size(12, 24);

  .ja {
    display: block;
    font-size: clamp_size(16, 22, 768, 1280);
    font-weight: 700;
    line-height: 1;
    @include mq-down(md) {
      font-size: clamp_size(18, 22, 375, 768);
    }
  }
  
  .en {
    display: block;
    font-size: clamp_size(14, 15);
    font-weight: 700;
    margin-top: .3em;
  }

  &--green {
    .en {
      color: $clr_green;
    }
  }

  &--orange {
    .en {
      color: $clr_orange;
    }
  }

  &--red {
    .en {
      color: $clr_red;
    }
  }
}

.about__item_btn {
  margin-top: clamp_size(10, 20);
}

// ---------------------------------------------------------
// PhotoSlider
// ---------------------------------------------------------
.photoSlider {
  .swiper-slide {
    border-radius: 20px;

    &:nth-child(2n) {
      padding-top: 2rem;
    }

    img {
      border-radius: 20px;
    }
  }
}

.swiper {
  opacity: 0;
  visibility: hidden;
  &.swiper-initialized {
    opacity: 1;
    visibility: visible;
    pointer-events: none;
  }
}

/* スライドの動き等速 */
.swiper-wrapper {
  transition-timing-function: linear;
}

/* 画像のサイズ調整 */
.swiper-slide img {
  width: 100%;
  height: auto;
}

// ---------------------------------------------------------
// お知らせ・ブログ
// ---------------------------------------------------------
.news {
  margin-top: clamp_size(60, 100);
}

.news__inner {
  display: flex;
  justify-content: space-between;
  @include mq-down(md) {
    flex-direction: column;
  }
}

.news__head {
  width: 25.6rem;
  @include mq-down(md) {
    width: 100%;
  }
}

.news__head_moreLink {
  margin-top: clamp_size(54, 74);
  @include mq-down(md) {
    display: none;
  }
}

.news__list {
  width: 65.17%;
  @include mq-down(md) {
    width: 100%;
    margin-top: 3rem;
  }
}

.news__moreLink {
  display: none;
  @include mq-down(md) {
    display: block;
    margin-left: auto;
    margin-top: 5rem;
  }
}

// ---------------------------------------------------------
// 問診票・職員募集バナー notice
// ---------------------------------------------------------

.notice__item {
  display: flex;
  justify-content: center;
  border-radius: 2.5rem;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  &:not(:first-child) {
    margin-top: 5.0rem;
  }
  @include mq-down(md) {
    flex-direction: column;
  }
}

.notice__item .inner{
  flex: 0 0 auto;
  width: 50%;
  max-width: 100%;
  @include mq-down(md) {
    flex: unset;
    width: 100%;
  }
}


.notice__item-img img{
  border-radius: 2.5rem 0 0 2.5rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
  @include mq-down(md) {
    border-radius: 2.5rem 2.5rem 0 0 ;
  }
}

.notice__item-txt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-block: 0;
  background-color: #fff;
  border-radius: 0 2.5rem 2.5rem 0;
  @include mq-down(md) {
    padding:3rem;
    border-radius: 0 0 2.5rem 2.5rem;
  }
}

.notice__item-txt-ttl {
  font-size: 2.2rem;
  line-height: 3.2rem;
  font-weight: 700;
  &::after {
    content: attr(data-eng);
    display: block;
    font-size: 1.5rem;
    letter-spacing: 0.02em;
    color: #0db97d;
    line-height: 2.1rem;
  }
}

.notice__item-txt-desc {
  font-size: 1.5rem;
  line-height: 2.1rem;
  font-weight: 700;
  display: block;
}

.notice__item-txt-btn {
  margin-top: 2rem;
  &.md {
    display: none;
    @include mq-down(md) {
      display: block;
  }
}
}

.notice__item-txt-btn i {
  margin-right: 1rem;
}

.notice__item-txt-wrap {
  padding-block: 3rem;
  @include mq-down(md) {
    padding-block: unset;
  }
}

.notice__item-qr {
  & img {
    width: clamp_size(100, 120);
    @include mq-down(md) {
      display: none;
    }
  }
  & p {
    font-weight: 700;
    line-height: 2.4rem;
    & span {
      @include mq-down(md) {
        display: none;
      }
    }
    @include mq-down(md) {
      margin-top: 1.2rem;
    }
  }
}

.notice__item-dl {
  & p {
    font-weight: bold;
  }
  & br {
    display: none;
    @include mq-down(md) {
      display: block;
    }
  }
}