@charset "utf-8";

///////////////////////////////////////////////////////////
// wave
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-wave {
  position: relative;

  &--bottom {
    padding-bottom: 2.65vw;
    @include mq-down(md) {
      padding-bottom: clamp_size(15,20,375,768);
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 1px;
      width: 100%;
      height: 2.65vw;
      @include mq-down(md) {
        height: clamp_size(15,20,375,768);
      }
    }
  }

  &--top {
    padding-top: 2.65vw;

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -1px;
      width: 100%;
      height: 2.65vw;
      @include mq-down(md) {
        height: clamp_size(15,20,375,768);
      }
    }
  }

  &--green {
    &::before {
      background: url("img/common/img_bg04.svg") no-repeat;
      background-size: cover;
      background-position: center;
    }

    &::after {
      background: url("img/common/img_bg01.svg") no-repeat;
      background-size: cover;
      background-position: center;
    }
  }

  &--orange {
    &::before {
      background: url("img/common/img_bg04.svg") no-repeat;
      background-size: cover;
      background-position: center;
    }

    &::after {
      background: url("img/common/img_bg05.svg") no-repeat;
      background-size: cover;
      background-position: center;
    }
  }

  &--cta {
    &::before,
    &::after {
      z-index: 1;
    }

    &::before {
      background: url("img/common/cta_mask01.svg") no-repeat;
      background-size: cover;
      background-position: center;
      top: -1px;
    }

    &::after {
      background: url("img/common/cta_mask02.svg") no-repeat;
      background-size: cover;
      background-position: center;
      bottom: -1px;
    }
  }
}