@charset "utf-8";

///////////////////////////////////////////////////////////
// btn
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-btn {
  position: relative;
  display: inline-block;
  font-size: clamp_size(14, 16);
  font-weight: 600;
  text-align: center;
  width: 100%;
  max-width: 34.5rem;
  box-sizing: border-box;
  border: 2px solid transparent;
  background-color: $clr_black;
  transition: all .3s ease;
  @include linkColor(#fff);
  @include hover {
    color: $clr_black;
    border: 2px solid $clr_black;
    background-color: #fff;
  }

  > span {
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
  }

  &--light-green {
    background-color: $clr_medium_green;
    @include linkColor($clr_green);
    @include hover {
      color: $clr_green;
      border: 2px solid $clr_green;
      background-color: #fff;
    }
  }

  &--green {
    background-color: $clr_green;
    @include linkColor(#fff);
    @include hover {
      color: $clr_green;
      border: 2px solid $clr_green;
      background-color: #fff;

      &::before {
        color: $clr_green;
      }
    }
  }

  &--light-orange {
    background-color: $clr_medium_orange;
    @include linkColor($clr_orange);
    @include hover {
      color: $clr_orange;
      border: 2px solid $clr_orange;
      background-color: #fff;
    }
  }

  &--orange {
    background-color: $clr_orange;
    @include linkColor(#fff);
    @include hover {
      color: $clr_orange;
      border: 2px solid $clr_orange;
      background-color: #fff;

      &::before {
        color: $clr_orange;
      }
    }
  }

  &--light-red {
    background-color: $clr_light_red;
    @include linkColor($clr_red);
    @include hover {
      color: $clr_red;
      border: 2px solid $clr_red;
      background-color: #fff;
    }
  }

  &--red {
    background-color: $clr_dark_red;
    @include linkColor(#fff);
    @include hover {
      color: $clr_dark_red;
      border: 2px solid $clr_dark_red;
      background-color: #fff;

      &::before {
        color: $clr_dark_red;
      }
    }
  }

  &--square {
    font-size: 2rem;
    padding: .48em .1em;
    border-radius: 5px;
  }
  
  &--rounded {
    padding: .6em 1em;
    border-radius: 25px;
  }

  &--icon-l {
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      position: static;
      transform: translateY(1px);
      font-size: 1.5rem;
      color: #fff;
      margin-right: .7em;
      transition: color .3s ease;
    }
  }

  &--icon-r {
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      position: static;
      transform: translateY(0);
      font-size: 1.5rem;
      color: #fff;
      margin-left: .7em;
      transition: color .3s ease;
    }

    @include hover {
      &::after {
        color: $clr_black;
      }
    }
  }

  &--arrow-r {
    &::after {
      content: "\f0da";
      font-size: 1.2em;
      font-weight: 900;
      font-family: "Font Awesome 6 Free";
      position: absolute;
      top: 50%;
      right: 1em;
      transform: translateY(-50%);
    }
  }

  &--arrow-l {
    &::after {
      content: "\f0d9";
      font-size: 1.2em;
      font-weight: 900;
      font-family: "Font Awesome 6 Free";
      position: absolute;
      top: 50%;
      left: 1em;
      transform: translateY(-50%);
    }
  }

  &--arrow-b {
    &::after {
      content: "\f0dd";
      font-size: 1.2em;
      font-weight: 900;
      font-family: "Font Awesome 6 Free";
      position: absolute;
      top: 50%;
      right: 1em;
      transform: translateY(-58%);
    }
  }

  &--infoFile {
    max-width: 38.6rem;
    padding: 0.45em 1em;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 2rem;
      transform: translateY(-50%);
      background: url("img/icon/icon_dl_white.svg") no-repeat;
      background-size: cover;
      width: 1.3rem;
      height: 1.1rem;
    }

    @include hover {
      &::after {
        background: url("img/icon/icon_dl_orange.svg") no-repeat;
        background-size: cover;
      }
    }
  }

  &--infoMenu {
    max-width: 53.2rem;
    padding: 0.45em 1em;

    &::after {
      content: "\f08e";
      font-size: .8em;
      font-weight: 900;
      font-family: "Font Awesome 6 Free";
      color: #fff;
      position: absolute;
      top: 50%;
      right: 2rem;
      transform: translateY(-50%);
    }

    @include hover {
      &::after {
        color: $clr_orange;
      }
    }
  }
}