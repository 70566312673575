@charset "utf-8";

///////////////////////////////////////////////////////////
// header
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.header {
  position: fixed;
  top: 3rem;
  left: 0;
  right: 0;
  width: 100%;
  height: 6.8rem;
  z-index: $z-header;
  transition: opacity .4s ease;
  animation-name: headerUpAnime;
  animation-duration: .6s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  @keyframes headerUpAnime {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @include mq-down(md) {
    top: 2rem;
  }
  @include mq-down(sm) {
    top: 1rem;
  }

  &.is-active {
    top: 2rem;
    height: 6.3rem;
    opacity: 0;
    animation-name: headerDownAnime;
    animation-duration: .4s;
    animation-delay: .4s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;

    .header__logo {
      width: clamp_size(230,258,992,1280);
      @include mq-down(lg) {
        width: clamp_size(218,258,375,992);
      }
    }

    .header__navLink {
      @include mq-up(lg) {        
        font-size: clamp_size(13,14,992,1280);
        padding: .4em .6em;
      }
    }

    .header__navTel_head {
      // @include mq-up(lg) {
      //   display: none;
      // }
    }

    .header__navTel {
      @include mq-up(lg) {
        transform: translateY(2px);
      }
    }

    .header__navTel_number {
      .num {
        @include mq-up(lg) {
          font-size: clamp_size(17,20,992,1280);
        }
      }
    }

    @keyframes headerDownAnime {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
}

.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
}

.header__logo {
  position: relative;
  width: clamp_size(230,298,992,1280);
  transition: opacity .4s ease;
  @include mq-down(lg) {
    width: clamp_size(268,318,375,992);
  }
  @media screen and (max-width: 359px) {
    width: 22.8rem;
  }

  &.is-opacity {
    opacity: 0;
  }
}

.header__logo_link {
  display: block;
}

.header__nav {
  width: clamp_size(670,786,992,1280);
  height: inherit;
  padding: 0 clamp_size(20,30,992,1280) 0 clamp_size(11,21,992,1280);
  background-color: #fff;
  border-radius: 10px 10px 0 10px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  @include mq-down(lg) {
    position: fixed;
    top: 0;
    right: 0;
    width: 40vw;
    max-width: 50rem;
    height: 100vh;
    height: 100dvh;
    padding: 0;
    background-color: $clr_medium_green;
    border-radius: 0;
    box-shadow: none;
    transform: translateX(100%);
    transition: transform .4s ease-in-out;
    pointer-events: none;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: $z-nav;

    &.is-open {
      transform: translateX(0);
      pointer-events: auto;
    }
  }
  @include mq-down(lg) {
    width: 50vw;
    padding: clamp_size(40, 110, 768, 992) clamp_size(40, 72, 768, 992);
  }
  @include mq-down(md) {
    width: 70vw;
    padding-left: clamp_size(32, 72, 576, 768);
    padding-right: clamp_size(32, 72, 576, 768);
  }
  @include mq-down(sm) {
    width: 100vw;
    max-width: 100%;
    padding-left: clamp_size(28, 72, 375, 576);
    padding-right: clamp_size(28, 72, 375, 576);
  }
  @media screen and (max-width: 359px) {
    padding-left: 4%;
    padding-right: 4%;
  }
}

.header__navList {
  display: flex;
  align-items: center;
  height: inherit;
  @include mq-down(lg) {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }
}

.header__navItem {
  @include mq-down(lg) {
    width: 100%;
  }

  &:nth-child(1) {
    .header__navLink {
      .text {
        &::before {
          width: 1.4rem;
          height: 1.4rem;
          background: url("img/icon/icon_info_orange.svg") no-repeat;
          background-size: cover;
          @include mq-down(lg) {
            width: 2rem;
            height: 2rem;
          }
        }
      }
    }
  }

  &:nth-child(2) {
    .header__navLink {
      .text {
        &::before {
          width: 1.4rem;
          height: 1.4rem;
          background: url("img/icon/icon_department_orange.svg") no-repeat;
          background-size: cover;
          @include mq-down(lg) {
            width: 2rem;
            height: 2rem;
          }
        }
      }
    }
  }

  &:nth-child(3) {
    .header__navLink {
      .text {
        padding-left: 1.1rem;
        @include mq-down(lg) {
          padding-left: 1.5em;
        }

        &::before {
          width: .9rem;
          height: 1.3rem;
          background: url("img/icon/icon_faq_orange.svg") no-repeat;
          background-size: cover;
          @include mq-down(lg) {
            width: 1.8rem;
            height: 2.6rem;
          }
        }
      }
    }
  }

  &:nth-child(4) {
    .header__navLink {
      .text {
        padding-left: 2.1rem;
        @include mq-down(lg) {
          padding-left: 1.5em;
        }

        &::before {
          width: 1.8rem;
          height: 1.6rem;
          background: url("img/icon/icon_abouthospital_orange.svg") no-repeat;
          background-size: cover;
          @include mq-down(lg) {
            height: 2.2rem;
            width: 2.3rem;
          }
        }
      }
    }
  }

  &:nth-child(5) {
    .header__navLink {
      .text {
        padding-left: 1.8rem;
        @include mq-down(lg) {
          padding-left: 1.5em;
        }

        &::before {
          content: "\f3c5";
          font-family: "Font Awesome 6 Free";
          font-size: 1.2em;
          font-weight: 700;
          color: $clr_orange;
        }
      }
    }
  }
}

.header__navLink {
  display: inline-block;
  font-size: clamp_size(13,14,992,1280);
  padding: .5em .6em;
  border-radius: 1rem;
  transition: background-color .3s ease;
  @include mq-down(lg) {
    padding: 1rem 1.5rem;
    transition: color .3s ease;
  }
  
  .text {
    position: relative;
    font-size: inherit;
    font-weight: 500;
    padding-left: 1.3em;
    @include mq-down(lg) {
      font-size: 2rem;
      padding-left: 1.5em;
    }
    @media screen and (max-width: 359px) {
      font-size: 1.8rem;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
    }
  }

  @include hover {
    background-color: $clr_medium_green;
    @include mq-down(lg) {
      color: $clr_orange;
    }
  }
}

.header__navTel {
  text-align: center;
  margin-left: auto;
  @include mq-down(lg) {
    display: none;
  }
}

.header__navTel_number {
  display: block;
  line-height: 1;

  .num {
    position: relative;
    font-size: clamp_size(18,21,992,1280);
    font-weight: 500;
    line-height: 1;
    padding-left: 1em;
    transition: color .3s ease;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: 1.3rem;
      height: 1.3rem;
      background: url("img/icon/icon_tel_orange.svg") no-repeat;
      background-size: cover;
    }
  }

  @include hover {
    .num {
      color: $clr_orange;
    }
  }
}

.header__navTel_head {
  display: block;
  font-size: 1.1rem;
  font-weight: 500;
  color: #fff;
  background: $clr_orange;
  border-radius: 1rem;
  line-height: 1.6rem;
}

.header__navTel_time {
  display: block;
  font-size: 1rem;
}

.header__conversion {
  display: none;
  
  &.p-conversionUnits {
    @include mq-down(lg) {
      display: grid;
      margin-top: clamp_size(20,30,375,992);
    }
  }
}

.header__navReservation {
    text-align: right;
    & a {
      line-height: 4rem;
      background: #0db97d;
      color: #fff;
      font-weight: 700;
      display: inline-block;
      padding-inline: 16px;
      border-radius: 0 0 10px 10px;
      box-shadow: 0 2px 20px #00000029;
      border: 2px solid #0db97d;
      transition: 0.3s all linear;
      &::before {
        content:"\f073";
        font-family: Font Awesome\ 6 Free;
        margin-right: 10.5px;
      }
      &:hover {
        background: #fff;
        color: #0db97d;
      }
    }
   @include mq-down(lg) {
     display: none;
  }
}

/* ハンバーガーメニュー
---------------------------------------------------------- */
.header__navMenu {
  display: none;
  position: relative;
  z-index: $z-navBtn;
  @include mq-down(lg) {
    display: block;
  }
}

.header__navBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  margin-left: auto;
  border-radius: 50%;
  background: $clr_green;
  tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @include mq-down(sm) {
    width: 4.8rem;
    height: 4.8rem;
  }

  &.is-open {
    .header__navBtn_line {
      &:nth-child(1) {
        transform: translate(-50%, 7px) rotate(45deg);
        @include mq-down(sm) {
          transform: translate(-50%, 5px) rotate(45deg);
        }
      }
      &:nth-child(2) {
        transform: translate(-50%, -7px) rotate(-45deg);
        @include mq-down(sm) {
          transform: translate(-50%, -5px) rotate(-45deg);
        }
      }
    }
  }
}

.header__navBtn_inner {
  position: relative;
  width: 3.4rem;
  height: 1.8rem;
  @include mq-down(sm) {
    width: 2.2rem;
    height: 1.3rem;
  }
}

.header__navBtn_line {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  width: 100%;
  height: .4rem;
  background: #fff;
  border-radius: 2px;
  transition: transform .5s ease-in-out;
  @include mq-down(sm) {
    height: .3rem;
  }

  &:nth-child(1) {
    top: 0;
  }
  &:nth-child(2) {
    bottom: 0;
  }
}

.header__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  transform: translateX(100%);
  background-color: rgba(225,225,225, 0.1);
  backdrop-filter: blur(1.5rem);
  z-index: $z-headerBg;
  transition: transform .4s ease-in-out;
  @include mq-down(sm) {
    display: none;
  }

  &.is-open {
    transform: translateX(0);
  }
}

/* メニュー展開時 背景固定
---------------------------------------------------------- */
body.is-open {
  height: 100%;
  overflow: hidden;
}