@charset "utf-8";

///////////////////////////////////////////////////////////
// aside
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.l-aside {
  width: 21.63%;
  @include mq-down(md) {
    width: 100%;
    max-width: 35rem;
    margin: 5rem auto 0;
  }
}

.l-aside__title {
  color: $clr_orange;
  font-weight: 700;
  margin-bottom: 1.5rem;
  @include mq-down(md) {
    text-align: center;
    margin-bottom: clamp_size(10, 15);
  }
}

.l-aside__categories {
  position: relative;
  padding: 1.5rem 0;
  background-position: top left 0px;
  background-repeat: repeat-x;
  background-size: .4em .1em;
  background-image: radial-gradient(.05em .05em at center center,$clr_light_gray,$clr_light_gray 100%,transparent,transparent);

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: .4em;
    background-position: bottom left 0px;
    background-repeat: repeat-x;
    background-size: .4em .1em;
    background-image: radial-gradient(.05em .05em at center center,$clr_light_gray,$clr_light_gray 100%,transparent,transparent);
  }
}

.l-aside__category_link {
  position: relative;
  display: block;
  padding-left: 1.2em;

  &::before {
    content: "\f0da";
    font-weight: 900;
    font-family: "Font Awesome 6 Free";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: clamp_size(15, 18);
    color: $clr_orange;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease, visibility .3s ease;
  }

  &:hover,
  &:focus,
  &.is-current {
    &::before {
      opacity: 1;
      visibility: visible;
    }
  }
}

.l-aside__archive {
  margin-top: 2.5rem;
  @include mq-down(md) {
    margin-top: clamp_size(20, 30);
  }
}

.l-aside__archive_selectWrap {
  position: relative;
  @include mq-down(md) {
    max-width: 16rem;
    margin: 0 auto;
  }

  &::after {
    content: "\f0d7";
    font-weight: 900;
    font-family: "Font Awesome 6 Free";
    position: absolute;
    top: 50%;
    right: 7%;
    transform: translateY(-50%);
    font-size: clamp_size(15, 18);
    line-height: 1;
    color: $clr_orange;
  }
}

.l-aside__archive_select {
  width: 100%;
  padding: 0.25em 1.25em;
  font-size: clamp_size(14,16,768,1280);
  font-weight: 700;
  color: $clr_black;
  border-radius: 17px;
  background-color: $clr_light_orange;
}