@charset "utf-8";

///////////////////////////////////////////////////////////
// error
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.error__thumb {
  text-align: center;
}

.error__message {
  text-align: center;
  margin-top: clamp_size(20, 40);
}

.error__btn {
  margin-top: clamp_size(30, 50);
  text-align: center;
}