@charset "utf-8";

///////////////////////////////////////////////////////////
// single
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.article {

  h1 {
    position: relative;
    font-size: clamp_size(24, 32);
    font-weight: 700;
    margin-top: 1rem;
    line-height: 1.5625;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
    }

    span {
      font-size: inherit;
    }
  }

  .addtoany_content {
    margin-top: 0;
    margin-bottom: 0;
  }

  .addtoany_list {
    display: flex;
  }

  .a2a_button_facebook_like {
    width: auto !important;
  }

  .a2a_button_facebook,
  .a2a_button_twitter,
  .addtoany_share_save {
    margin-top: 0;
  }

  iframe {
    height: 20px !important;
  }
}

.article__btn {
  margin-top: clamp_size(40, 80);
}

.content {

  /* SNS レイアウト */
  .addtoany_share_save_container {
    padding: clamp_size(16, 20) 0;
    margin-bottom: clamp_size(40, 60);
    background-position: bottom left 0px;
    background-repeat: repeat-x;
    background-size: .4em .1em;
    background-image: radial-gradient(.05em .05em at center center,$clr_light_gray,$clr_light_gray 100%,transparent,transparent);
  }

  h2 {
    font-size: clamp_size(22, 26);
    font-weight: 700;
    color: $clr_orange;
    margin-bottom: 1.6em;
    text-align: left;
    line-height: 1.5;
  }

  h3 {
    position: relative;
    font-size: clamp_size(18, 24);
    font-weight: 700;
    padding-left: 1rem;
    margin-bottom: 1.6em;
    line-height: 1.625;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: 4px;
      height: 100%;
      border-radius: 2px;
      background-color: $clr_orange;
    }
  }

  h4, h5, h6 {
    font-size: clamp_size(15, 18);
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 1em;
  }

  p {
    font-size: clamp_size(14, 15);
    text-align: justify;
    line-height: 1.8;

    & + p,
    & + a {
      margin-top: 2rem;
    }

    & + * {
      margin-top: clamp_size(20, 40);
    }
  }

  strong {
    font-size: clamp_size(14, 15);
    line-height: 1.8;
  }

  blockquote {
    position: relative;
    margin: clamp_size(40, 60) 0 !important;
    padding: 10% 11%;
    background-color: $clr_light_orange;

    &::before,
    &::after {
      content: "“";
      position: absolute;
      font-size: clamp_size(68, 136);
      color: $clr_orange;
      line-height: 1;
    }

    &::before {
      top: -1.9rem;
      left: 2.5%;
      transform: scale(-1, 1) rotate(180deg);
    }

    &::after {
      bottom: -1.9rem;
      right: 2.5%;
      transform: scale(-1, 1) rotate(360deg);
    }
  }

  figure {
    & + p {
      margin-top: 2rem;
    }

    & + * {
      margin-top: clamp_size(20, 40);
    }

    figcaption {
      font-size: clamp_size(12, 14);
      margin-top: .8em;
    }

    img {
      height: auto;
    }
  }

  img {
    height: auto;
  }

  ol {
    list-style-type: none;
    counter-reset: item;

    & + * {
      margin-top: clamp_size(20, 40);
    }

    li {
      position: relative;
      font-size: clamp_size(14, 15);
      padding-left: 1.5em;

      & + li {
        margin-top: 1rem;
      }
      
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        counter-increment: item;
        content: counter(item)'.';
        font-size: clamp_size(14, 15);
        font-weight: bold;
        color: $clr_green;
      }
    }
  }
  
  ul {
    list-style: none;

    & + * {
      margin-top: clamp_size(20, 40);
    }
    
    li {
      position: relative;
      font-size: clamp_size(14, 15);
      padding-left: 1.5em;

      & + li {
        margin-top: 1rem;
      }

      &::before {
        position: absolute;
        top: clamp_size(8, 9);
        left: 0;
        content: "";
        display: inline-block;
        width: 1.1rem;
        height: 1.1rem;
        margin-right: 0.5em;
        border-radius: 50%;
        background-color: $clr_dark_red;
      }
    }
  }

  a {
    position: relative;
    display: inline-block;
    font-size: clamp_size(14, 15);
    font-weight: 500;
    color: $clr_orange;
    text-decoration: underline;
    word-wrap: break-word;
    text-underline-offset: 0.3ex;

    & + p,
    & + a {
      margin-top: 2rem;
    }

    & + * {
      margin-top: clamp_size(20, 40);
    }

    @include hover {
      text-decoration: none;
    }
  }

  .tableWrapper {
    width: 100%;
    padding-bottom: 1.5rem;
    white-space: nowrap;
    overflow-x: auto;

    & + p {
      margin-top: 2rem;
    }

    & + * {
      margin-top: clamp_size(20, 40);
    }
  }

  table {
    width: 82.5rem;
    border-spacing: 0;
    border-collapse: collapse;

    thead {
      border-bottom: none;
    }
    
    tr {
      background-color: transparent !important;
    }
    
    th,
    td {
      font-size: clamp_size(15, 18);
      padding: 1.2em 1.35em;
      border: 1px solid $clr_black;

      small {
        display: block;
        font-size: clamp_size(14, 15);
        line-height: 1.6;
        margin-top: .5rem;
      }
    }
    
    th {
      font-weight: bold;
      text-align: left;
      background: $clr_light_orange;
    }

    a {
      white-space: nowrap;
    }
  }
}