@charset "utf-8";

///////////////////////////////////////////////////////////
// fixedBnr
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-fixedBnr {
  position: fixed;
  top: clamp_size(200, 300);
  right: 0;
  z-index: $z-fixedItem;
  @include mq-down(sm) {
    display: none;
  }
}

.p-fixedBnr__link {
  display: grid;
  place-content: center;
  width: 5rem;
  width: clamp_size(40, 50);
  height: 16.8rem;
  height: clamp_size(128, 168);
  border-radius: 10px 0 0 10px;
  background-color: $clr_orange;
  border: 2px solid transparent;
  transition: border .3s ease, background-color .3s ease;

  .text {
    display: inline-block;
    font-size: clamp_size(14, 16);
    font-weight: 600;
    color: #fff;
    padding-top: 1.2em;
    writing-mode: vertical-rl;
    text-orientation: upright;
    transition: color .3s ease;

    &::before {
      font-size: 1em;
      color: #fff;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: color .3s ease;
    }
  }

  @include hover {
    border: 2px solid $clr_orange;
    background-color: #fff;

    .text {
      color: $clr_orange;

      &::before {
        color: $clr_orange;
      }
    }
  }

}