@charset "utf-8";

///////////////////////////////////////////////////////////
// archive
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.archive__pager {
  margin-top: 5rem;
  @include mq-down(md) {
    margin-top: clamp_size(50, 60);
  }
}