@charset "utf-8";

///////////////////////////////////////////////////////////
// circleLink
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-circleLink {
  position: relative;
  display: inline-block;
  font-size: clamp_size(14, 16);
  padding-right: 3.125em;

  .text {
    position: relative;
    display: inline-block;
    font-size: inherit;
    font-weight: 700;
    color: $clr_green;
    z-index: 1;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: $clr_green;
    }
  }

  .circle {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: clamp_size(85, 100);
    height: clamp_size(85, 100);
    background-color: $clr_medium_green;
    border-radius: 50%;
    transition: transform .3s cubic-bezier(0.25,0.46,0.45,0.94);
  }

  @include hover {
    .circle {
      transform: translateY(-50%) scale(0.7);
    }
  }
}