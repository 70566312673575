@charset "utf-8";

///////////////////////////////////////////////////////////
// pager
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.wp-pagenavi {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: clamp_size(30, 50, 768, 1280);
  @include mq-down(md) {
    margin-top: clamp_size(30, 50, 375, 768);
  }

  a,
  span {
    padding: 0 !important;
  }

  .page,
  .current,
  .previouspostslink,
  .nextpostslink,
  .extend {
    margin: 0 clamp_size(5, 10);
  }
  
  .page,
  .current,
  .previouspostslink,
  .nextpostslink {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp_size(14, 16);
    font-weight: 500;
    width: clamp_size(40, 50);
    height: clamp_size(40, 50);
    color: #fff;
    border-radius: 10px;
  }

  .page,
  .previouspostslink,
  .nextpostslink {
    color: $clr_black;
    position: relative;
    border: 2px solid $clr_orange;
    background-color: #fff;
    transition: color .3s ease, background-color .3s ease;

    @include hover {
      color: #fff;
      background-color: $clr_orange;
      border: 2px solid $clr_orange;
    }
  }

  span.current {
    color: #fff;
    background-color: $clr_orange;
    border: 2px solid $clr_orange !important;
    pointer-events: none;
    box-sizing: content-box;
  }

  .previouspostslink,
  .nextpostslink {
    &::after {
      display: inline-block;
      vertical-align: middle;
      transition: color .3s ease;
    }
    @include hover {
      &::after {
        color: #fff;
      }
    }
  }

  .previouspostslink {    
    &::after {
      content: "\f0d9";
      font-size: 1.2em;
      font-weight: 900;
      font-family: "Font Awesome 6 Free";
      margin-left: .1rem;
      color: $clr_orange;
    }
  }

  .nextpostslink {
    &::after {
      content: "\f0da";
      font-size: 1.2em;
      font-weight: 900;
      font-family: "Font Awesome 6 Free";
      margin-right: .1rem;
      color: $clr_orange;
    }
  }

  .first,
  .last {
    display: none;
  }

  .extend {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    width: 1rem;
    height: 1rem;
    border-radius: 10px;
    border: none;
    transform: translateY(-50%);

    &:last-of-type {
      display: none;
    }
  }
}