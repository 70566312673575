@charset "utf-8";

///////////////////////////////////////////////////////////
// footer
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.footer {
  padding: clamp_size(50, 60) 0 clamp_size(20, 30);
  background-color: $clr_white_green;
}

.footer__inner {
  display: flex;
  justify-content: space-between;
  @include mq-down(md) {
    flex-direction: column;
  }
}

.footer__head {
  width: 47%;
  @include mq-down(md) {
    width: 100%;
    text-align: center;
  }
}

.footer__headWrap {
  display: flex;
  gap: 2rem;
  align-items: center;
  @include mq-down(md) {
    flex-direction: column;
  }
  & figure {
    transition: 0.3s all linear;
    &:hover {
      opacity: 0.7;
    }
  }
}

.footer__logo {
  @include mq-down(md) {
    text-align: center;
  }
}

.footer__logo_link {
  display: inline-block;
}

.footer__tel {
  margin-top: 2rem;
  @include mq-down(md) {
    text-align: center;
  }
}

.footer__head_bottom {
  width: fit-content;
  text-align: left;
  flex-shrink: 0;
  // margin-top: 1.2rem;
  @include mq-down(md) {
    margin: clamp_size(15,20,375,768) auto 0;
  }
}

.footer__fax,
.footer__address {
  font-size: 1.2rem;
  font-weight: 500;
  padding-left: 1.8em;
  line-height: 1.7;
  @include mq-down(md) {
    width: fit-content;
    margin: 0 auto;
    transform: translateY(-.5rem);
  }
  @media screen and (max-width: 359px) {
    font-size: 1.1rem;
  }

  &::before {
    color: $clr_orange;
  }
}

.footer__address {
  &::before {
    left: 2px;
    top: 0;
    transform: translateY(0);
    @include mq-down(md) {
      left: 7px;
    }
  }
}

.footer__map {
  width: 46.7%;
  aspect-ratio: 430 / 203;
  border-radius: 20px;
  @include mq-down(md) {
    width: 100%;
    margin-top: 2.8rem;
  }

  iframe {
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }
}

.footer__nav {
  background-position: top left 0px;
  background-repeat: repeat-x;
  background-size: .6em .4em;
  background-image: radial-gradient(.1em .1em at center center,$clr_green,$clr_green 100%,transparent,transparent);
  margin-top: 4rem;
}

.footer__navList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 3rem;
  margin-left: -3.6rem;
  margin: -.5rem;
  @include mq-down(sm) {
    margin-left: -1.8rem;
  }
}

.footer__navItem {
  margin-left: 3.6rem;
  margin-top: .5rem;
  @include mq-down(sm) {
    margin-left: 1.8rem;
  }
}

.footer__navItem_link {
  font-size: 1.2rem;
  font-weight: 500;
  padding-left: 1.2em;

  &::before {
    color: $clr_orange;
    transform: translateY(-46%);
  }

  &.u-icon-right-outlook {
    padding-right: 1.2em;

    &::after {
      font-size: .9em;
      color: $clr_orange;
      transform: translateY(-46%);
    }
  }

  @include hover {
    color: $clr_orange;
  }
}

.footer__copyright {
  margin-top: clamp_size(30, 40);
}

.footer__copyright_text {
  font-size: clamp_size(11, 12);
  text-align: center;
}

.footer__outpatientDayTableWrap {
  width: 100%;
  margin-top: clamp_size(20, 30);
  padding-bottom: 1rem;
  @include mq-down(md) {
    overflow-x: scroll;
  }
}

.footer__outpatientDayTable {
  margin-top: .5em;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
  text-align: center;
  @include mq-down(md) {
    width: 48rem;
  }

  thead {
    color: #FFF;
    background-color: $clr_green;
    
    th {
      width: 15.8%;
      padding: 1rem 0;
      font-size: clamp_size(14, 15);
      font-weight: 500;

      &:not(:first-child) {
        border-left: 1px solid #FFF;
      }

      &:first-child {
        width: 19%;
        border-radius: 10px 0 0 0;
      }

      &:last-child {
        border-radius: 0 10px 0 0;
      }
    }
  }

  tbody {
    background-color: #fff;

    tr {
      &:last-child {
        th {
          border-radius: 0 0 0 10px;
        }

        
        td {
          &:last-child {
            border-radius: 0 0 10px 0;
          }
        }
      }

      &:not(:last-child) {
        th {
          border-bottom: 1px solid $clr_green;
        }

        td {
          border-bottom: 1px solid $clr_green;
        }
  
      }
    }

    th,
    td {
      font-weight: 500;
      padding: 1rem 0;
    }
    
    th {
      font-size: clamp_size(12, 14);
      line-height: 1.2;
    }

    td {
      font-size: 1.6rem;
      color: $clr_green;
      border-left: 1px solid $clr_green;

      &.holiday {
        background-image: linear-gradient(to right top, transparent calc(50% - 0.5px), $clr_green 50%, $clr_green calc(50% + 0.5px), transparent calc(50% + 1px));
      }
    }
  }
}

.footer__outpatientDayTable_caution {
  font-size: clamp_size(12, 13);
  font-weight: 700;
  margin-top: .5em;
  line-height: 1.4;
  @include mq-down(md) {
    text-align: left;
  }
}