@charset "utf-8";

///////////////////////////////////////////////////////////
// anchorLink
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-anchorLink {
  display: flex;
  justify-content: center;
  @include mq-down(sm) {
    flex-direction: column;
    align-items: center;
  }
}

.p-anchorLink__item {
  width: 100%;
  max-width: 33.8rem;

  & + & {
    margin-left: clamp_size(10, 50, 576, 1280);
    @include mq-down(sm) {
      margin-left: 0;
      margin-top: 2rem;
    }
  }
}