@charset "utf-8";

///////////////////////////////////////////////////////////
// conversion
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.l-conversion {
  position: relative;
  background: url("img/common/img_cta@2x.png") no-repeat;
  background-size: cover;
  @include mq-down(md) {
    background-position: center right 15%;
  }
}

.l-conversion__inner {
  padding: 1rem 0 4rem;
  padding: 0.78vw 0 3.125vw;
  padding: max(0.78vw, 1rem) 0 max(3.125vw, 4rem);
}