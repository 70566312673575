@charset "utf-8";

///////////////////////////////////////////////////////////
// section
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.l-section {
  margin-top: clamp_size(80, 100);
}