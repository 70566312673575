@charset "utf-8";

///////////////////////////////////////////////////////////
// telUnit
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-telUnit {
  &--flex {
    display: flex;
    align-items: center;

    @include mq-down(lg) {
      flex-direction: column;
      align-items: flex-start;
    }

    @include mq-down(md) {
      align-items: center;
    }

    .p-telUnit__time {
      margin-left: 1.1em;

      @include mq-down(lg) {
        margin-left: 0;
      }
    }
    &Item {
      text-align: center;
    }
  }

  &--contact {
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq-down(md) {
      flex-direction: column;
      align-items: center;
    }

    .p-telUnit__timeWrap {
      margin-top: 0.1em;

      @include mq-down(md) {
        padding-left: 0;
      }
    }

    .p-telUnit__time {
      font-size: 1.2rem;
      font-weight: 400;
      margin-top: 0;
      margin-left: 1.5em;
      transform: translateY(3px);

      @include mq-down(lg) {
        margin-left: 0;
      }
    }
    &Item {
      text-align: center;
    }
  }
  & .bold {
    font-weight: 700;
  }
}

.p-telUnit__desc {
  border-radius: 1rem;
  background: $clr_green;
  color: #fff;
  padding: 0 2rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.6rem;
}

.p-telUnit__num {
  position: relative;
  font-size: clamp_size(28, 33);
  font-weight: 700;
  line-height: .6;
  // padding-left: .9em;
  transition: color .3s ease;
  display: block;
  margin-bottom: 0.1em;

  &::before {
    content: "";
    // position: absolute;
    // top: 50%;
    // left: 0;
    // transform: translateY(-40%);
    display: inline-block;
    width: 2.4rem;
    height: 2.4rem;
    background: url("img/icon/icon_tel.svg") no-repeat;
    background-size: cover;
    margin-right: 1rem;

    @include mq-down(lg) {
      width: 2rem;
      height: 2rem;
    }
  }

  @include hover {
    color: $clr_green;
  }

  &.small {
    font-size: clamp_size(20, 28);
    padding-left: unset;
    &::before {
      content: none;
    }
  }
}

.p-telUnit__timeWrap {
  @include mq-down(lg) {
    font-size: clamp_size(28, 36);
    padding-left: .9em;
  }
}

.p-telUnit__time {
  font-size: clamp_size(10, 12);
  font-weight: 400;
  margin-top: .8rem;
  line-height: 1.4;
}