@charset "utf-8";

///////////////////////////////////////////////////////////
// contact
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.contact__tel {
  margin-bottom: clamp_size(40, 70);
}

.contact__tel_container {
  width: min(87%,92rem);
  padding: 4rem 2rem;
  margin: 0 auto;
  border-radius: 10px;
  background-color: #fff;
}

.contact__tel_content {
  margin-top: clamp_size(20, 40);
}

.contact__tel_message {
  margin-top: 1.8rem;
  text-align: center;
  line-height: 1.8;
}

.contact__tel_num {
  margin-top: 2.4rem;
}

// ---------------------------------------------------------
// メールでのお問い合わせ
// ---------------------------------------------------------
.contact__mail {
  &--confirm,
  &--complete {
    padding: 0;
  }
}

.contact__mail_container {
  width: min(87%,92rem);
  padding: clamp_size(30, 60) 0 clamp_size(40, 70);
  margin: 0 auto;
}

.contact__mail_head {
  margin-top: clamp_size(20, 40);
}

.contact__mail_steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2rem;
  @include mq-down(md) {
    flex-direction: column;
  }
}

.contact__mail_step {
  position: relative;
  width: calc((100% - 10%) / 3);
  font-size: clamp_size(14, 15);
  font-weight: 700;
  text-align: center;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 10px;
  @include mq-down(md) {
    max-width: 30rem;
    width: 100%;
    margin: 0 auto;
  }

  & + & {
    @include mq-down(md) {
      margin-top: 3.2rem;
    }

    &::before {
      content: "\f0da";
      font-size: 1.4em;
      font-weight: 900;
      font-family: "Font Awesome 6 Free";
      color: $clr_orange;
      position: absolute;
      top: 50%;
      left: -10%;
      transform: translateY(-50%);
      @include mq-down(md) {
        content: "\f0d7";
        top: -62%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &.is-current {
    color: #fff;
    background-color: $clr_orange;
  }
}

.contact__mail_cautions {
  margin-top: 3rem;
}

.contact__mail_caution {
  padding-left: 1.2em;
  text-indent: -1.2em;
  line-height: 1.7;

  & + & {
    margin-top: .5rem;
  }
}

// ---------------------------------------------------------
// コンタクトフォーム
// ---------------------------------------------------------
.contact__form {
  margin-top: 3rem;

  /* 確認画面
  ---------------------------------------------------------- */
  &--confirm {
    margin-top: clamp_size(40, 50);

    .contact__confirm_text {
      font-weight: 500;
      text-align: center;
      margin-bottom: clamp_size(20, 30);
    }

    .contact__form_btn {
      display: flex;
      justify-content: center;
      @include mq-down(md) {
        flex-direction: column;
        align-items: center;
      }

      .c-btn {
        &:not(:first-child) {
          margin-left: 2rem;
          @include mq-down(md) {
            margin-left: 0;
            margin-top: 1.6rem;
          }
        }
      }
    }
  }

  /* 完了画面
  ---------------------------------------------------------- */
  &--complete {
    margin-top: clamp_size(40, 50);

    .contact__complete_title {
      font-size: clamp_size(20, 32);
      font-weight: 700;
      text-align: center;
      color: $clr_orange;
    }
  
    .contact__complete_text {
      text-align: center;
      margin-top: 2rem;
  
      & + & {
        margin-top: 1.2em;
      }
    }
  }

  .mw_wp_form.mw_wp_form_send_error {
    font-size: clamp_size(14, 16);
    text-align: center;
    padding: 3rem 0;
  }

  .error {
    font-size: clamp_size(14, 15) !important;
    font-weight: 500;
    color: $clr_red !important;
    &::before {
      content: "\f06a";
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      margin-right: 0.4em;
    }
  }
}

.contact__form_block {
  display: flex;
  @include mq-down(md) {
    flex-direction: column;
  }

  & + & {
    margin-top: clamp_size(20, 30);
  }
}

.contact__form_label {
  font-size: clamp_size(14, 15);
  font-weight: 700;
  width: 16.5rem;
  @include mq-down(md) {
    width: 100%;
  }

  /* ラベル */
  label {
    display: flex;
    align-items: center;
    font-size: inherit;
    font-weight: inherit;
  }

  /* 必須ラベル */
  .require {
    display: inline-block;
    font-size: clamp_size(14, 16);
    color: $clr_orange;
    margin-left: .1em;
  }
}

.contact__form_field {
  width: calc(100% - 16.5rem);
  @include mq-down(md) {
    width: 100%;
    margin-top: .8rem;
  }
}

.contact__form_input {
  width: 100%;
  font-size: clamp_size(13, 15);
  padding: .7em 1.3em;
  background-color: #fff;
  border-radius: 5px;
  box-sizing: border-box;
  @include mq-down(md) {
    padding: .7em 1em;
  }

  &::placeholder {
    color: #D1D1D1;
  }

  &:focus {
    outline: 2px solid $clr_orange;
  }

  &--textarea {
    min-height: 24rem;
  }
}

/* プライバシー確認 */
.contact__form_privacy {
  margin-top: 3rem;
  text-align: center;
}

.contact__form_privacy_text {
  font-size: clamp_size(14, 15);
}

.contact__form_privacy_link {
  position: relative;
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: 0.3ex;
  @include linkColor($clr_orange);
  @include hover {
    text-decoration: none;
  }
}

.p-form__checkbox {
  opacity: 0;
  position: absolute;

  + span.mwform-checkbox-field-text {
    position: relative;
    display: inline-block;
    padding: .3em .3em .2em 1.8em;
    font-size: clamp_size(14, 15);
    line-height: 1;
    vertical-align: middle;
    cursor: pointer;

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      content: "";
      width: 2rem;
      height: 2rem;
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #707070;
      line-height: 1;
      box-sizing: border-box;
    }

    &::after {
      content: "";
      display: none;
    }
  }

  &:focus + span.mwform-checkbox-field-text {
    outline: 2px solid $clr_orange;
  }

  &:checked + span.mwform-checkbox-field-text::after {
    position: absolute;
    top: clamp_size(3, 4);
    left: .75rem;
    content: "";
    display: block;
    width: .3rem;
    height: 1rem;
    transform: rotate(45deg);
    border-bottom: 3px solid $clr_orange;
    border-right: 3px solid $clr_orange;
  }
}

.contact__form_btn {
  text-align: center;
  margin-top: clamp_size(40, 50);
}