@charset "utf-8";

///////////////////////////////////////////////////////////
// About hospital & Access
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

// ---------------------------------------------------------
// コンバージョンエリア調整
// ---------------------------------------------------------
.l-page-about {
  .c-wave--cta {
    &::before {
      background: url("img/common/img_bg01.svg") no-repeat;
      background-size: cover;
      background-position: center;
      top: -1px;
    }
  }
}

// ---------------------------------------------------------
// 理事長挨拶
// ---------------------------------------------------------
.about__greeting {
  padding: clamp_size(44, 66) 0;
  background-color: $clr_light_orange;
}

.about__greeting_inner {
  display: flex;
  justify-content: space-between;
  margin-top: clamp_size(30, 50);

  @include mq-down(lg) {
    flex-direction: column-reverse;
  }
}

.about__greeting_body {
  width: 65.7%;

  @include mq-down(lg) {
    max-width: 62rem;
    width: 100%;
    margin: 3rem auto 0;
  }
}

.about__greeting_message {
  font-size: clamp_size(20, 32);
  font-weight: 700;
  color: $clr_orange;
  line-height: 1.5625;
}

.about__greeting_content {
  margin-top: clamp_size(20, 40);
}

.about__greeting_text {
  &+& {
    margin-top: 0.95em;
  }
}

.about__greeting_photo {
  width: 30%;

  @include mq-down(lg) {
    width: 100%;
    text-align: center;
  }

  figure {
    border-radius: 10px;
  }
}

.about__greeting_photo_inner {
  display: inline-block;
}

.about__greeting_name {
  font-size: clamp_size(16, 18);
  font-weight: 700;
  text-align: right;
}

// ---------------------------------------------------------
// クリニック概要
// ---------------------------------------------------------
.about__hospital {
  margin-top: clamp_size(30, 50);
}

.about__hospital_wrap {
  display: flex;

  &:last-child {
    .about__hospital_title {
      position: relative;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        width: 100%;
        height: .1em;
        display: block;
        background-position: bottom left 0px;
        background-repeat: repeat-x;
        background-size: .4em .1em;
        background-image: radial-gradient(.05em .05em at center center, $clr_orange, $clr_orange 100%, transparent, transparent);
      }
    }

    .about__hospital_text {
      position: relative;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        width: 100%;
        height: .1em;
        display: block;
        background-position: bottom left 0px;
        background-repeat: repeat-x;
        background-size: .4em .1em;
        background-image: radial-gradient(.05em .05em at center center, $clr_gray, $clr_gray 100%, transparent, transparent);
      }
    }
  }
}

.about__hospital_title,
.about__hospital_text {
  font-size: clamp_size(14, 16);
  padding: .95em 0;
}

.about__hospital_title {
  width: clamp_size(64, 144);
  font-weight: 500;
  background-position: top left 0px;
  background-repeat: repeat-x;
  background-size: .4em .1em;
  background-image: radial-gradient(.05em .05em at center center, $clr_orange, $clr_orange 100%, transparent, transparent);
}

.about__hospital_text {
  width: 83.4%;
  margin-left: 4.4%;
  background-position: top left 0px;
  background-repeat: repeat-x;
  background-size: .4em .1em;
  background-image: radial-gradient(.05em .05em at center center, $clr_light_gray, $clr_light_gray 100%, transparent, transparent);

  .outlook {
    transition: color .3s ease;

    @include hover {
      color: $clr_orange;
    }
  }

  .u-icon-right-outlook {
    padding-right: 1.4em;
    transition: color .3s ease;

    &::after {
      font-size: .9em;
      color: $clr_orange;
      transform: translateY(-46%);
    }

    @include hover {
      color: $clr_orange;
    }
  }
}

// ---------------------------------------------------------
// アクセス
// ---------------------------------------------------------
.red {
  color: $clr_dark_red;
}

.line {
  text-decoration: underline;
}



.about__access {
  padding: clamp_size(44, 66) 0 clamp_size(70, 100);
  background-color: $clr_light_green;
}

.about__access_alert {
  text-align: center;
  font-size: clamp_size(15, 20);
  line-height: 1.5;
  font-weight: 700;
  color: #00ACBA;
  margin-top: clamp_size(20, 28);
}

.about__access_text {
  margin-top: clamp_size(30, 40);
}

.about__map {
  padding-block: clamp_size(36, 60);
  border-bottom: 1px solid #00ACBA;
}

.about__map_inner {
  aspect-ratio: 1114 / 270;

  @include mq-down(md) {
    aspect-ratio: 1114 / 470;
  }

  @include mq-down(sm) {
    aspect-ratio: 1114 / 670;
  }

  iframe {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}

.about__map_detail {
  margin-top: 1rem;
  text-align: right;
}

.about__map_detail_link {
  font-weight: 700;
  padding-right: 1.2em;
  transition: color .3s ease;

  &::after {
    font-size: 1em;
    color: $clr_orange;
  }

  @include hover {
    color: $clr_orange;
  }
}

.about__route-box {
  padding-block: clamp_size(24, 40);
  border-bottom: 1px solid #00ACBA;
}

.about__route-ttl {
  font-size: clamp_size(15, 20);
  line-height: 1.5;
  font-weight: 700;
}

.about__route-dep {
  font-size: clamp_size(16, 18);
  font-weight: 700;
  line-height: 1.5;
  margin-top: clamp_size(16, 20);
}

.about__route-desc {
  font-size: clamp_size(14, 16);
  line-height: 1.5;
  font-weight: 500;
  margin-top: clamp_size(16, 20);
}

.about__route {
  margin-top: clamp_size(18, 20);
}

.about__route_list {
  display: grid;
  gap: clamp_size(50, 100, 992, 1280);
  grid-template-columns: repeat(3, 1fr);
  padding-left: clamp_size(20, 85);
  padding-right: clamp_size(20, 85);
  margin-top: 2rem;

  @include mq-down(lg) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.about__route_thumb {
  text-align: center;

  @include mq-down(lg) {
    max-width: 48.8rem;
    margin: 0 auto;
    text-align: center;

    img {
      width: 100%;
    }
  }
}

.about__route_content {
  display: flex;
  margin-top: clamp_size(15, 20);

  @include mq-down(lg) {
    max-width: 48.8rem;
    margin: 1rem auto 0;
    justify-content: center;
  }
}

.about__route_num {
  font-size: clamp_size(24, 38);
  font-weight: 500;
  color: $clr_orange;
  line-height: 1;
}

.about__route_text {
  line-height: 2;
  margin-left: 1rem;
}

.about__car .about__route-desc {
  margin-top: unset;
}

.about__car-inner {
  display: grid;
  grid-template-columns: calc(50% - 2.5rem) calc(50% - 2.5rem);
  gap: 5rem;

  @include mq-down(md) {
    grid-template-columns: 100%;
    gap: 2rem;
  }
}

.about__car-item {
  &.matsuura {
    .about__route-desc {
      padding-bottom: 2rem;

      @include mq-down(md) {
        padding-bottom: unset;
      }
    }
  }
}

.about__car-detail {
  margin-top: 2.5rem;
  background: #fff;
  border-radius: 1rem;
  padding: clamp_size(10, 30);
  text-align: center;

  p {
    font-size: 1.7rem;
    font-weight: 700;
    color: initial;
  }

  .point {
    color: #fff;
    padding-block: 1.3rem;
    border-radius: 0.5rem;
  }

  .start {
    &.point {
      background: $clr_orange;
      margin-bottom: 1.5rem;
    }
  }

  .destinaiton {
    &.point {
      background: $clr_green;
    }
  }

  .info {
    background: $clr_light_green;
    padding: 2rem;
    display: flex;
    align-items: center;
    gap: 3rem;
    margin-bottom: 2rem;
  }

  .road {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    &::before {
      content: "";
      display: block;
      width: 1.469rem;
      height: 3.378rem;
      background: url("./img/about-access/icon_arrow@2x.png") no-repeat;
      background-size: cover;
    }
  }

  & .road_wrap {
    & .road {
      justify-content: flex-start;
      padding-left: 25%;

      @include mq-down(md) {
        padding-left: 20%;
      }
    }
  }

  & .route {
    color: initial;
    background: $clr_light_green;
    margin-bottom: 1.5rem;

    &.arrow {
      margin-bottom: 6.4rem;
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 1.469rem;
        height: 3.378rem;
        background: url("./img/about-access/icon_arrow@2x.png") no-repeat;
        background-size: cover;
        left: 50%;
        transform: translateX(-50%);
        bottom: -4.5rem;
      }
    }
  }
}

.about__parking {
  padding-inline: clamp_size(20, 80);
  padding-right: clamp_size(20, 80);
  margin-top: 3rem;
  margin-bottom: clamp_size(20, 40);
}

.about__parking_inner {
  display: flex;
  align-items: center;
  margin-top: 2rem;

  @include mq-down(xl) {
    flex-direction: column;
  }
}

.about__parking_item {
  margin-top: 4rem;
  & p {
    font-weight: 700;
  }
}

.about__parking_img {
  margin-top: clamp_size(12, 20);
  display: grid;
  grid-template-columns: calc((100% - 4rem) / 2 ) calc((100% - 4rem) / 2 );
  gap: clamp_size(20, 40);

  & figure {
    img {
      width: 100%;
      height: auto;
    }
  }

  @include mq-down(md) {
    grid-template-columns: 100%;
  }
}

.about__partking_thumb {
  text-align: center;
  margin-top: 1.8rem;
}

.about__parking_thumbsWrap {
  width: 61%;
}

.about__parking_thumbs {
  display: flex;
  width: 100%;

  @include mq-down(xl) {
    width: 100%;
    flex-direction: column;
  }
}

.about__parking_thumb {
  width: calc(25% - 2rem / 3);

  @include mq-down(xl) {
    width: 100%;
    max-width: 48.8rem;
    margin: 0 auto;
  }

  &+& {
    margin-left: 2rem;

    @include mq-down(xl) {
      margin-left: auto;
      margin-top: 2rem;
    }
  }

  img {
    @include mq-down(xl) {
      width: 100%;
    }
  }

  &.kadomaru {
    border-radius: 10px;
    overflow: hidden;
  }

  &--wide {
    width: calc(50% - 2rem / 3);

    @include mq-down(xl) {
      width: 100%;
      max-width: 48.8rem;
      margin: 0 auto;
    }
  }
}

.about__parking_text {
  line-height: 2;
  margin-left: clamp_size(20, 40);

  @include mq-down(xl) {
    margin-left: 0;
    margin-top: 1rem;
  }
}

.about__parking_caution {
  line-height: 2;
  margin-top: clamp_size(20, 40);
  font-weight: 700;
  text-indent: -1.2rem;
  padding-left: 1.2rem;
}