@charset "utf-8";

///////////////////////////////////////////////////////////
// Information
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

// ---------------------------------------------------------
// 当院の特長
// ---------------------------------------------------------
.information__feature {
  margin-top: clamp_size(60, 100);
}

.information__feature_inner {
  padding: clamp_size(42, 84) 0;
  background-color: $clr_light_orange;
}

.information__featureListWrap {
  margin-top: clamp_size(36, 56);
  @include mq-down(sm) {
    margin-top: 0;
  }
}

.information__featureList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: -11.3rem;
  margin-left: -4.8rem;
  @include mq-down(xl) {
    margin-top: -8.3rem;
    margin-left: -3.8rem;
  }
  @include mq-down(lg) {
    margin-top: -5.3rem;
  }
  @include mq-down(md) {
    margin-top: -4rem;
    margin-left: -3rem;
  }
  @include mq-down(sm) {
    flex-direction: column;
    margin-left: 0;
    margin-top: 0;
  }
}

.information__featureItem {
  position: relative;
  width: 33.8rem;
  margin-top: 11.3rem;
  margin-left: 4.8rem;
  @include mq-down(xl) {
    width: 28.8rem;
    margin-top: 8.3rem;
    margin-left: 3.8rem;
  }
  @include mq-down(lg) {
    margin-top: 5.3rem;
  }
  @include mq-down(md) {
    width: clamp_size(248,268,576,768);
    margin-top: 4rem;
    margin-left: 3rem;
  }
  @include mq-down(sm) {
    width: clamp_size(300,360,375,576);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

.information__featureItem_head {
  position: relative;
  width: 33.8rem;
  @include mq-down(xl) {
    width: 26.8rem;
  }
  @include mq-down(md) {
    width: clamp_size(248,268,576,768);
  }
  @include mq-down(sm) {
    width: clamp_size(204,248,375,576);
    margin: 0 auto;
  }
}

.information__featureItem_num {
  position: absolute;
  top: -.6rem;
  left: -.6rem;
  font-size: 6.8rem;
  font-weight: 700;
  line-height: 1;
  color: $clr_orange;
  @include mq-down(sm) {
    font-size: clamp_size(58, 68, 375, 576);
  }
}

.information__featureItem_thumb {
  display: grid;
  place-content: center;
  width: 33.8rem;
  height: 33.8rem;
  border-radius: 50%;
  background-color: #fff;
  @include mq-down(xl) {
    width: 26.8rem;
    height: 26.8rem;
  }
  @include mq-down(md) {
    width: clamp_size(248,268,576,768);
    height: clamp_size(248,268,576,768);
  }
  @include mq-down(sm) {
    width: clamp_size(204,248,375,576);
    height: clamp_size(204,248,375,576);
  }

  img {
    @include mq-down(md) {
      width: 90%;
      margin: 0 auto;
    }
    @include mq-down(sm) {
      width: 80%;
    }
  }
}

.information__featureItem_title {
  font-size: clamp_size(14, 18);
  font-weight: 700;
  text-align: center;
  line-height: 1.4;
  margin-top: 1.4em;
}

.information__featureItem_text {
  text-align: left;
  margin-top: .85em;
}

// ---------------------------------------------------------
// 外来
// ---------------------------------------------------------
.information__openDayTable {
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
  width: 100%;
  margin-top: clamp_size(30, 40);
  @include mq-down(sm) {
    tr,th,td {
      display: block;
    }
  }

  tr {
    &:first-child {
      th {
        border-radius: 10px 0 0 0;
        @include mq-down(sm) {
          border-radius: 10px 10px 0 0;
        }
      }

      td {
        border-radius: 0 10px 0 0;
        @include mq-down(sm) {
          border-radius: 0;
        }
      }
    }

    &:last-child {
      th {
        border-radius: 0 0 0 10px;
        @include mq-down(sm) {
          border-radius: 0;
        }
      }

      td {
        border-radius: 0 0 10px 0;
        @include mq-down(sm) {
          border-radius: 0 0 10px 10px;
        }
      }
    }

    &:not(:last-child) {
      th {
        border-bottom: 1px solid #fff;
      }

      td {
        border-bottom: 1px solid $clr_green;
      }
    }
  }

  th,
  td {
    font-size: clamp_size(14, 15);
    
    &:last-child {
      border-right: none;
    }
  }

  th {
    width: 14.4rem;
    font-weight: 500;
    color: #FFF;
    text-align: center;
    padding: 2rem 0;
    background-color: $clr_green;
    @include mq-down(sm) {
      width: 100%;
      padding: 1.2rem 0;
    }
  }

  td {
    width: calc(100% - 14.4rem);
    padding: 2rem clamp_size(20, 50);
    background-color: $clr_light_green;
    @include mq-down(sm) {
      width: 100%;
    }
  }
}

/* 外来日（原則予約制）
---------------------------------------------------------- */
.information__outpatient {
  margin-top: clamp_size(42, 84);
}

.information__outpatient_inner {
  width: min(92%, 92rem);
  margin: 0 auto;
}

.information__outpatientDayTableWrap {
  width: 100%;
  margin-top: clamp_size(20, 30);
  padding-bottom: 1rem;
  overflow-x: auto;
}

.information__outpatientDayTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
  text-align: center;
  @include mq-down(md) {
    width: 65rem;
  }

  thead {
    color: #FFF;
    background-color: $clr_green;
    
    th {
      width: 15.8%;
      padding: 2rem 0;
      font-size: clamp_size(14, 15);
      font-weight: 500;

      &:not(:first-child) {
        border-left: 1px solid #FFF;
      }

      &:first-child {
        width: 19%;
        border-radius: 10px 0 0 0;
      }

      &:last-child {
        border-radius: 0 10px 0 0;
      }
    }
  }

  tbody {
    background-color: #fff;

    tr {
      &:last-child {
        th {
          border-radius: 0 0 0 10px;
        }

        
        td {
          &:last-child {
            border-radius: 0 0 10px 0;
          }
        }
      }

      &:not(:last-child) {
        th {
          border-bottom: 1px solid $clr_green;
        }

        td {
          border-bottom: 1px solid $clr_green;
        }
  
      }
    }

    th,
    td {
      font-weight: 500;
      padding: 1.7rem 0;
    }
    
    th {
      font-size: clamp_size(14, 15);
      line-height: 1.8;
    }

    td {
      font-size: 2.6rem;
      color: $clr_green;
      border-left: 1px solid $clr_green;

      &.holiday {
        background-image: linear-gradient(to right top, transparent calc(50% - 0.5px), $clr_green 50%, $clr_green calc(50% + 0.5px), transparent calc(50% + 1px));
      }
    }
  }
}

.information__outpatientDayTable_caution {
  font-size: clamp_size(12, 13);
  padding-left: 1em;
  text-indent: -1em;
  margin-top: .5em;
}

.information__outpatient_info {
  padding-left: clamp_size(20, 30);
  padding-right: clamp_size(20, 30);
  margin-top: clamp_size(22, 32);
}

.information__outpatient_infoData {
  display: flex;
  @include mq-down(lg) {
    flex-direction: column;
  }

  & + & {
    margin-top: 2rem;
  }
}

.information__outpatient_infoTitle {
  width: fit-content;
  font-size: clamp_size(14, 15);
  font-weight: 500;
}

.information__outpatient_infoText {
  font-size: clamp_size(14, 15);
  margin-left: 2em;
  @include mq-down(lg) {
    margin-left: 0;
    margin-top: .5rem;
  }
}

.information__outpatient_infoCautions {
  margin-top: 1rem;
}

.information__outpatient_infoCaution {
  font-size: clamp_size(12, 13);
  padding-left: 1em;
  text-indent: -1em;
}

/* 問診票テンプレートをダウンロード
---------------------------------------------------------- */
.information__sheet {
  text-align: center;
  margin-top: clamp_size(30, 40);
}

.information__sheet-box {
  margin-top: clamp_size(24, 30);

  & img {
    width: clamp_size(120, 140);
    margin-top: clamp_size(16, 20);
    @include mq-down(md) {
      display: none;
    }
  }
}

.information__sheet_text-b {
  font-weight: 700;
}

.information__sheet_btn {
  margin-top: clamp_size(20, 25);

  .c-btn {
    margin: 0 auto;
  }
}

/* 様々な病状について一人ひとりに合わせた治療を行います
---------------------------------------------------------- */
.information__menu {
  margin-top: clamp_size(64, 84);
}

.information__menu_inner {
  width: min(90%, 92rem);
  margin: clamp_size(24, 34) auto 0;
}

.information__menuList {
  display: grid;
  gap: 0 clamp_size(22, 33);
  grid-template-columns: repeat(5, auto);
  @include mq-down(lg) {
    width: 100%;
    max-width: 59.2rem;
    margin: 0 auto;
    grid-template-columns: repeat(4, auto);
  }
  @include mq-down(md) {
    max-width: 49rem;
    grid-template-columns: repeat(3, auto);
  }
  @include mq-down(sm) {
    max-width: 33rem;
    grid-template-columns: repeat(2, auto);
  }
}

.information__menuItem {
  font-size: clamp_size(13, 15);
  font-weight: 500;
}

.information__menuBtn {
  margin-top: clamp_size(26, 36);
  text-align: center;
}

.information__menuBtn_caution {
  font-size: clamp_size(14, 15);
  margin-top: 1rem;
}