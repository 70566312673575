@charset "utf-8";

///////////////////////////////////////////////////////////
// breadcrumb
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-breadcrumb {

  span[property="itemListElement"] {
    position: relative;
    display: inline-block;

    &:last-of-type {
      span[property="name"] {
        display: inline-block;
        padding: 0;
        margin-top: -.2rem;
        vertical-align: middle;
        max-width: 25rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  
  span[property="name"] {
    position: relative;
    display: inline-block;
    font-size: 1.3rem;
    transition: color .3s ease;
  }
  
  a[property="item"] {
    @include hover {
      span[property="name"] {
        color: $clr_orange;
      }
    }
  }

  .angle {
    position: relative;
    top: -1px;
    left: 10px;
    display: inline-block;
    font-size: 1.3rem;
  }
}