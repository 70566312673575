@use "../foundation/mixins" as *;

.u-icon {
  &-right {
    &-angle-right {
      @include icon("\f105", "right");
    }

    &-truck {
      @include icon("\f0d1", "right");
    }

    &-outlook {
      @include icon("\f08e", "right");
    }
  }

  &-scope {
    @include icon("\f0f1");
  }

  &-envelope {
    @include icon("\f0e0");
  }

  &-arrow-right {
    @include icon("\f0da");
  }

  &-location {
    @include icon("\f3c5");
  }

  &-file-pdf {
    @include icon("\f1c1");
  }

  &-fax {
    @include icon("\f1ac");
  }

  // &-facebook {
  //   @include icon("\f39e", "left", "brands");
  // }

  // &-twitter {
  //   @include icon("\f099", "left", "brands");
  // }
}
