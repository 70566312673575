@charset "utf-8";

///////////////////////////////////////////////////////////
// suvFv
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-subFv {
  text-align: center;
}

.p-subFv__inner {
  padding: clamp_size(95, 135) 0 clamp_size(40, 50);
  background-color: $clr_light_green;
}