@charset "utf-8";

///////////////////////////////////////////////////////////
// 変数定義用SASS
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// ブレイクポイント
// ---------------------------------------------------------
$breakpoint_up: (
  sm: "screen and (min-width: 576px)",
  md: "screen and (min-width: 768px)",
  lg: "screen and (min-width: 992px)",
  xl: "screen and (min-width: 1200px)",
  xxl: "screen and (min-width: 1400px)"
);
$breakpoint_down: (
  sm: "screen and (max-width: 575.98px)",
  md: "screen and (max-width: 767.98px)",
  lg: "screen and (max-width: 991.98px)",
  xl: "screen and (max-width: 1199.98px)",
  xxl: "screen and (max-width: 1399.98px)"
);

// ---------------------------------------------------------
// レイアウト
// ---------------------------------------------------------
// コンテナ幅
$container_width: ( fluid:100%, md:720px, lg:960px, xl:1140px, xxl:1320px );

// 余白
$gap: 3rem; // 30px

// ---------------------------------------------------------
// z-index
// ---------------------------------------------------------
$z-navBtn: 500;
$z-nav: 400;
$z-header: 400;
$z-headerBg: 350;
$z-fixedItem: 200;

// ---------------------------------------------------------
// hover
// ---------------------------------------------------------
$opacity: .6;
$transition_opacity: opacity .3s ease;

// ---------------------------------------------------------
// カラー
// ---------------------------------------------------------
// グレー系
$clr_black: #444;
$clr_gray: #CCC;
$clr_light_gray: #C6C6C6;

// グリーン系
$clr_green: #0DB97D;
$clr_medium_green: #ECFAF6;
$clr_light_green: #F6F9F8;
$clr_white_green: #F4F8F7;

// レッド系
$clr_red: #C46868;
$clr_dark_red: #C75151;
$clr_light_red: #FAEEEE;

// オレンジ系
$clr_orange: #FAAC1A;
$clr_medium_orange: #FFF2DB;
$clr_light_orange: #FFF7E7;

// ブルー系
$clr_blue: #0D77B9;
$clr_light_blue: #D1E9F8;

// ---------------------------------------------------------
// デフォルトフォント設定
// ---------------------------------------------------------
// フォントサイズ
$fnt_size_default: 1.6rem;

// 太さ
$fnt_weight_default: 400;

// 行間
$fnt_lh_default: 2;

// フォントファミリー
@import url('https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@400;500;700;900&display=swap');
$fnt_gothic: 'Zen Maru Gothic', "ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","Meiryo UI","メイリオ",Meiryo,"ＭＳ Ｐゴシック","MS PGothic",sans-serif;