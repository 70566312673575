@charset "utf-8";

///////////////////////////////////////////////////////////
// newsUnit
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-newsUnit {
  position: relative;

  &::after {
    position: absolute;
    left: 0;
    bottom: -1px;
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    background-position: bottom left 0px;
    background-repeat: repeat-x;
    background-size: .41em .1em;
    background-image: radial-gradient(.05em .05em at center center,$clr_light_gray,$clr_light_gray 100%,transparent,transparent);
    z-index: 1;
  }

  &:first-child {
    &::before {
      position: absolute;
      top: -1px;
      left: 0;
      content: "";
      width: 100%;
      height: 4px;
      background-position: top left 0px;
      background-repeat: repeat-x;
      background-size: .41em .1em;
      background-image: radial-gradient(.05em .05em at center center,$clr_light_gray,$clr_light_gray 100%,transparent,transparent);
      z-index: 1;
    }
  }
}

.p-newsUnit__link {
  position: relative;
  display: grid;
  grid-template-columns: 18rem 1fr;
  align-items: center;
  gap: 2rem;
  padding: 2rem 2rem 2rem 0;
  transition: background-color .3s ease;
  @include mq-down(md) {
    gap: .2rem;
    grid-template-columns: repeat(1, 1fr);
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &::after {
    content: "\f0da";
    font-weight: 900;
    font-family: "Font Awesome 6 Free";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 1.3em;
    color: $clr_orange;
  }

  @include hover {
    background-color: #ecfaf6;
  }
}

.p-newsUnit__title {
  font-size: clamp_size(14, 16);
  font-weight: bold;
}

.p-newsUnit__announce {
  font-weight: 500;
  text-align: center;
  margin-top: 5rem;
}