@charset "utf-8";

///////////////////////////////////////////////////////////
// heading
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-heading {
  font-size: clamp_size(22, 32);
  font-weight: 700;
  line-height: 1.5;

  > span {
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
  }

  .en {
    display: block;
    
    .text {
      position: relative;
      display: inline-block;
      font-size: clamp_size(14, 18);
      font-weight: 500;
      color: $clr_orange;
      margin-bottom: 0.5em;
      padding-bottom: 0.55em;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        right: 0;
        transform: translateX(-50%);
        width: 105%;
        background-position: left bottom;
        background-repeat: repeat-x;
        background-size: .5em .2em;
        background-image: radial-gradient(.08em .08em at center center,$clr_orange,$clr_orange 100%,transparent,transparent);
      }
    }

    &--green {
      .text {
        color: $clr_green;
        
        &::after {
          background-image: radial-gradient(.08em .08em at center center,$clr_green,$clr_green 100%,transparent,transparent);
        }
      }
    }
  }

  .ja {
    display: block;
    line-height: 1.5;
  }

  .headingWrap {
    display: inline-block;
  }

  &--xsmall {
    font-size: clamp_size(14, 15);
    color: $clr_orange;
    line-height: 1.86;
  }

  &--small {
    font-size: clamp_size(16, 18);
  }

  &--large {
    font-size: clamp_size(18, 24);
    line-height: 1.6;
  }

  &--subFv {
    .en {
      .text {
        padding-bottom: 0;

        &::after {
          display: none;
        }
      }
    }
  }

  &--dotted {
    .dotted {
      background-position: top left 0px;
      background-repeat: repeat-x;
      background-size: 1.0em .3em;
      background-image: radial-gradient(.07em .07em at center center,$clr_orange,$clr_orange 100%,transparent,transparent);
      padding-top: .2em;
    }
  }

  &--white {
    .en {
      .text {
        color: #fff;
        
        &::after {
          background-image: radial-gradient(.08em .08em at center center,#fff,#fff 100%,transparent,transparent);
        }
      }
    }

    .ja {
      color: #fff;
    }
  }

  &--green {
    color: $clr_green;
  }
}