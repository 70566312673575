@charset "utf-8";

///////////////////////////////////////////////////////////
// inner
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-inner {
  padding: clamp_size(30, 40) 0;
  border-radius: 20px;

  &--small {
    padding: 2rem 0;
    border-radius: 10px;
  }

  &--white {
    background-color: #fff;
  }

  &--green {
    background-color: $clr_light_green;
  }

  &--orange {
    background-color: $clr_light_orange;
  }
}