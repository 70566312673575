@charset "utf-8";

///////////////////////////////////////////////////////////
// pageTop
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

body {
  position: relative;
}

.p-pageTop {
  position: fixed;
  right: 1.5625%;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity .35s ease, visibility .35s ease;
  z-index: $z-fixedItem;
}

.p-pageTop__link {
  display: inline-block;
  @include hover {
    img {
      opacity: $opacity;
    }
  }

  img {
    transition: $transition_opacity;
  }
}