@charset "utf-8";

///////////////////////////////////////////////////////////
// staff
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

// ---------------------------------------------------------
// 共通設定
// ---------------------------------------------------------
.staff__thumb {
  width: 18rem;
  height: 18rem;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto clamp_size(15, 20);

  img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1 / 1;
  }
}

.staff__position,
.staff__name {
  font-weight: 500;
  line-height: 1.5625;
  text-align: center;
}

// ---------------------------------------------------------
// 医師
// ---------------------------------------------------------
.staff__units {
  & + & {
    margin-top: clamp_size(30, 50);
  }
}

.staff__unit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: clamp_size(20, 60);
  padding-right: clamp_size(20, 60);
  @include mq-down(md) {
    flex-direction: column;
  }

  & + & {
    margin-top: clamp_size(30, 50);
  }
}

.staff__unit_head {
  width: 18rem;
  align-self: start;
  @include mq-down(md) {
    width: 100%;
  }
}

.staff__unit_body {
  width: 76.4%;
  margin-left: 5%;
  @include mq-down(md) {
    width: 100%;
    margin-left: 0;
    margin-top: 2rem;
  }
}

.staff__unit_inner {
  padding: clamp_size(20, 30);
  background-color: #fff;
  border-radius: 10px;
  margin-top: 2rem;
}

.staff__unit_profile {
  display: flex;
  @include mq-down(sm) {
    flex-direction: column;
  }
  
  & + & {
    margin-top: 1.6rem;
  }
}

.staff__unit_profile_title {
  font-size: clamp_size(14, 16);
  font-weight: 500;
  width: 2em;
}

.staff__unit_profile_text {
  font-size: clamp_size(14, 16);
  width: calc(100% - 5.2rem);
  margin-left: 2rem;
  @include mq-down(sm) {
    margin-left: 0;
    width: 100%;
  }
}