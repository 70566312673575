@charset "utf-8";

///////////////////////////////////////////////////////////
// faq
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.faq {
  display: grid;
  gap: clamp_size(20,30,768,1280) clamp_size(20,50,768,1280);
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  margin-top: 2rem;
  @include mq-down(md) {
    grid-template-columns: repeat(1, 1fr);
    gap: 3rem;
  }
  @include mq-down(sm) {
    gap: 2rem;
  }
}

.faq__box {
  border-radius: 10px;
  background-color: $clr_light_orange;
}

.faq__wrap {
  display: flex;
}

.faq__icon {
  font-size: clamp_size(20, 27);
  font-weight: 700;
  line-height: 1;
}

.faq__content {
  margin-left: 1rem;
}

.faqWrap {
  .l-section {
    &:first-of-type {
      margin-top: 0;
    }
  }
}

/* Question
---------------------------------------------------------- */
.faq__question {
  position: relative;
  padding: 2rem 4.2rem 2rem clamp_size(15, 30);
  cursor: pointer;
  @include mq-down(md) {
    padding-right: clamp_size(36,42,375,768);
  }

  &::after {
    position: absolute;
    right: clamp_size(15, 20);
    top: 50%;
    transform: translateY(-50%);
    content: "\f0d7";
    font-weight: 900;
    font-family: "Font Awesome 6 Free";
    color: $clr_orange;
    font-size: 1.2em;
  }

  &.is-active {
    &::after {
      transform: translateY(-50%) rotate(-180deg);
    }
  }

  .faq__icon {
    color: $clr_orange;
    transform: translateY(-.2rem);
    @include mq-down(md) {
      transform: translateY(-.1rem);
    }
    @include mq-down(sm) {
      transform: translateY(0);
    }
  }
}

.faq__question_title {
  font-size: clamp_size(14, 15);
  font-weight: 600;
  line-height: 1.6;
}

/* Answer
---------------------------------------------------------- */
.faq__answer {
  display: none;
  padding: 0 4.2rem 2rem clamp_size(15, 30);
  transition: opacity .3s ease;
  transition-delay: .2s;
  opacity: 0;
  @include mq-down(md) {
    padding-right: clamp_size(36,42,375,768);
  }

  &.is-active {
    opacity: 1;
    display: block;
  }

  .faq__icon {
    color: $clr_red;
  }
}

.faq__answer_text {
  font-size: clamp_size(14, 15);
  text-align: justify;
  line-height: 1.85;
}

.faq__answer_smallText {
  font-size: 1.2rem;
  text-align: justify;
}