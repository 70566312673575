@charset "utf-8";

///////////////////////////////////////////////////////////
// newsInfo
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-newsInfo {
  display: flex;
  align-items: center;
}

.p-newsInfo__date {
  time {
    font-size: 1.2rem;
  }
}

.p-newsInfo__categories {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 0;
  line-height: 0;
  margin-left: clamp_size(10, 16);
}

.p-newsInfo__category {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 160%;
  padding: .15em .65em;
  margin-top: .3rem;
  margin-left: .3rem;
  border-radius: 11px;

  &--green {
    color: $clr_green;
    background-color: $clr_medium_green;
  }

  &--orange {
    color: $clr_orange;
    background-color: $clr_medium_orange;
  }

  &--red {
    color: $clr_red;
    background-color: $clr_light_red;
  }

  &--blue {
    color: $clr_blue;
    background-color: $clr_light_blue;
  }
}