@charset "utf-8";

///////////////////////////////////////////////////////////
// conversionUnit
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-conversionUnits {
  display: grid;
  gap: clamp_size(24,54,576,1280);
  grid-template-columns: repeat(2, 1fr);
  margin-top: 2rem;
  @include mq-down(sm) {
    grid-template-columns: repeat(1, 1fr);
    gap: clamp_size(12,24,375,576);
  }

  &--1col {
    grid-template-columns: repeat(1, 1fr);
    gap: clamp_size(12,20,375,992);
  }
}

.p-conversionUnit {
  display: grid;
  place-content: center;
  // height: 24.8rem;
  padding: 1.2rem 1.6rem;
  border-radius: 20px;

  &:nth-child(1) {
    background-color: $clr_medium_green;
    order: 1;
  }

  &:nth-child(2) {
    background-color: $clr_medium_orange;
    order: 3;
  }

  &:nth-child(3) {
    order: 2;
  }

  &--border {
    // height: 20rem;

    &:nth-child(1) {
      border: 2px solid $clr_green;
      background-color: $clr_light_green;
    }
  
    &:nth-child(2) {
      border: 2px solid $clr_orange;
      background-color: $clr_light_orange;
    }
  }
}

.p-conversionUnit__inner {
  text-align: center;
}

.p-conversionUnit__title {
  font-size: clamp_size(14, 15);
  font-weight: 500;

  .br {
    display: none;
    @media screen and (min-width: 576px) and (max-width: 768px) {
      display: block;
    }
  }
}

.p-conversionUnit__inner .p-conversionUnit__tel .text {
  text-align: left;
}

.p-conversionUnit__inner .p-conversionUnit__tel .text .p-telUnit__time {
  text-indent: clamp_size(-14, -16);
  padding-left: clamp_size(14, 16);
}

.p-conversionUnit__btn {
  margin-top: clamp_size(15, 20);

  @media screen and (min-width: 576px) and (max-width: 768px) {
    .c-btn {
      width: clamp_size(220,260,576,768);
      margin-left: auto;
      margin-right: auto;
    }
  }
}