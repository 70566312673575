@charset "utf-8";

///////////////////////////////////////////////////////////
// openDay
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-openDay {
  &--info {
    .p-openDay__wrap {
      @include mq-down(md) {
        flex-direction: row;
        &:not(:first-child) {
          margin-top: 1rem !important;
        }
      }
      @include mq-down(sm) {
        flex-direction: column;
      }

      &:nth-child(1),
      &:nth-child(2) {
        flex-direction: row;
      }
    }

    .p-openDay__detail {
      @include mq-down(md) {
        margin-top: 0;
      }
      @include mq-down(sm) {
        margin-top: .2em;
      }
    }
  }
}

.p-openDay__wrap {
  display: flex;
  line-height: 1.6;
  @include mq-down(md) {
    flex-direction: column;
  }

  & + & {
    margin-top: 1rem;
    @include mq-down(md) {
      margin-top: 2rem;
    }
  }
}

.p-openDay__title,
.p-openDay__detail {
  font-size: clamp_size(14, 15);
}

.p-openDay__title {
  width: 7em;
}

.p-openDay__detail {
  @include mq-down(md) {
    margin-top: .2em;
  }
}